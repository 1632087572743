html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@responsive {
  .text-shadow {
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  }

  .text-shadow-lg {
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  }
}
